body {
  background: var(--secondary-color);
  font-family: Poppins;
  transition: background auto 0.2s;
}

input:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  height: 4px; /* Height of the scrollbar */
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: var(--secondary-color);
}

/* Scrollbar handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 6px; /* Rounded corners */
}

/* Scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

button {
  transition: opacity 0.2s;
}
button:hover {
  opacity: 0.9;
}
button:active {
  opacity: 0.8;
}

:root {
  --primary-color: #315edd;
  --secondary-color: #f7f8fa;
  --text-color: #475366;
  --background-color: #f4f8ff;
  --backgroundhover-color: #e9edff;
  --background-secondary-color: #fdfdfd;
  --active-color: #d2e1f7;
  --invoice-color: #e9e9e9;
  --offer-background-color: #eaf1fb;
  --total-devices-color: #9bb6dc;
  --active-devices-color: #d5ffce;
  --inactive-color: #ededed;
  --border-color: #c9c4c4;
  --button-text-color: #fff;
  --notification-color: #fe7575;
  --error-color: red;
  --normal-color: #1b9b28;
  --waiting-button: #e3bb2b;
  --completed-button: #03e234;
  --loading-color: #f7f8fa70;
  --users-inactive-color: #475366;

  --modal-background-color: #d9d9d9a6;

  /* OTA start */

  --ota-headerhintbutton-background-color: var(--active-color);

  /* OTA end */

  /* Offers start */
  --inactive-coupon-color: var(--background-secondary-color);
  --offers-title-color: #475366;
  --corner-background-color: var(--active-color);
  /* --expired-background-color :#475366; */

  --active-background-color: #d5ffce;
  --active-font-color: #475366;

  --expired-font-color: #475366;
  --expired-background-color: #ffacac;

  /* Offers end */

  /* Subscription start */

  --subscription-dropdown-background: var(--active-color);

  /* Subscription end */
}

[data-theme="dark"] {
  --primary-color: #31a1dd;
  --secondary-color: #002536;
  --text-color: #aec5e9;
  --background-color: #3a484f;
  --backgroundhover-color: #2fa0d4;
  --background-secondary-color: #19303b;
  --active-color: #2fa0d4;
  --invoice-color: #e9e9e9;

  --users-inactive-color: #c5c5c5;

  --modal-background-color: #0e010187;

  /* OTA start */

  --ota-headerhintbutton-background-color: var(--background-secondary-color);

  /* OTA end */

  /* Offers start */
  --offer-background-color: #e9edff;
  --inactive-coupon-color: #181717;
  --offers-title-color: #aec5e9;

  --active-background-color: #475366;
  --active-font-color: #d5ffce;
  /* Offers end */

  /* Subscription start */

  --subscription-dropdown-background: var(--secondary-color);

  /* Subscription end */
}

/* Default Dark */
[data-theme="default-dark"] {
  --primary-color: #31a1dd;
  --secondary-color: #121212;
  --text-color: #aec5e9;
  --background-color: #0d2c3c;
  --backgroundhover-color: #124e6a;
  --background-secondary-color: #1e1e1e;
  --active-color: #124e6a;
  --invoice-color: #00364f;

  --active-devices-color: #445541;
  --inactive-color: #451f1f;
  --loading-color: #12121290;
  /* --border-color: #121212; */
  --border-color: var(--text-color);
  --users-inactive-color: #aeb0b1;
  --inactive-coupon-color: #181717;

  --modal-background-color: #0e010187;

  /* OTA start */

  --ota-headerhintbutton-background-color: var(--background-secondary-color);

  /* OTA end */

  /* Offers start */
  --offer-background-color: #1e1e1e;
  --inactive-coupon-color: #181717;

  --offers-title-color: #aec5e9;
  --corner-background-color: #1f2d36;

  --active-background-color: #475366;
  --active-font-color: #d5ffce;

  --expired-font-color: #fee1e1;
  --expired-background-color: #451f1f;

  /* Offers end */

  /* Subscription start */

  --subscription-dropdown-background: var(--loading-color);

  /* Subscription end */
}

/* Green Light */
[data-theme="green-light"] {
  --primary-color: #37bc9b;
  --secondary-color: #f7fbfa;
  --text-color: #475366;
  --background-color: #e3f4f1;
  --backgroundhover-color: #cdece7;
  --background-secondary-color: #fdfdfd;
  --active-color: #bfcecb;
  --invoice-color: #e9e9e9;

  --total-devices-color: #b8dcd3;

  /* Offers start */
  --offer-background-color: #e9f2ef;
  --corner-background-color: #d8e2e0;
  --offers-title-color: #46484d;

  --active-background-color: #d5ffce;
  --active-font-color: #475366;

  --expired-font-color: #475366;
  --expired-background-color: #ffacac;

  /* offers end */
}

/* Green Dark */
[data-theme="green-dark"] {
  --primary-color: #37bc9b;
  --secondary-color: #121212;
  --text-color: #aec5e9;
  --background-color: #0b342b;
  --backgroundhover-color: #2e766a;
  --background-secondary-color: #1e1e1e;
  --invoice-color: #19303b;

  --active-devices-color: #445541;
  --inactive-color: #451f1f;
  --loading-color: #12121290;
  --border-color: var(--text-color);

  --modal-background-color: #0e010187;

  /* OTA start */

  --ota-headerhintbutton-background-color: var(--background-secondary-color);

  /* OTA end */

  /* Offers start */

  --offer-background-color: #292727;
  --offers-title-color: var(--text-color);
  --corner-background-color: #363b3a;

  --active-background-color: #475366;
  --active-font-color: #d5ffce;

  --expired-font-color: #fee1e1;
  --expired-background-color: #451f1f;
  /* Offers end */

  /* Subscription start */

  --subscription-dropdown-background: var(--loading-color);

  /* Subscription end */
}

/* Purple Light */
[data-theme="purple-light"] {
  --primary-color: #7266ba;
  --secondary-color: #f9f8ff;
  --text-color: #475366;
  --background-color: #f4f4fc;
  --backgroundhover-color: #eaeaf6;
  --background-secondary-color: #fdfdfd;
  --active-color: #d0d0ff;
  --invoice-color: #e9e9e9;
  --offer-background-color: #f2f1fd;
  --total-devices-color: #c4bdef;

  /* Offers start */
  --active-background-color: #d5ffce;
  --active-font-color: #475366;

  --expired-font-color: #475366;
  --expired-background-color: #ffacac;

  /* Offers end */
}

/* Purple Dark */
[data-theme="purple-dark"] {
  --primary-color: #7266ba;
  --secondary-color: #121212;
  --text-color: #aec5e9;
  --background-color: #1f1c33;
  --backgroundhover-color: #48466e;
  --background-secondary-color: #1e1e1e;
  --invoice-color: #38363f;
  /* --offer-background-color: #1e1e1e; */
  --active-devices-color: #445541;
  --inactive-color: #451f1f;
  --loading-color: #12121290;
  --border-color: var(--text-color);
  --users-inactive-color: #a1a1a5;

  --modal-background-color: ##0e010187;

  /* OTA start */

  --ota-headerhintbutton-background-color: var(--background-secondary-color);

  /* OTA end */

  /* Offers start */
  --offer-background-color: #242121;
  --offers-title-color: var(--text-color);
  --corner-background-color: #30303d;

  --active-background-color: #475366;
  --active-font-color: #d5ffce;

  --expired-font-color: #fee1e1;
  --expired-background-color: #451f1f;
  /* Offers end */

  /* Subscription start */

  --subscription-dropdown-background: var(--loading-color);

  /* Subscription end */
}
